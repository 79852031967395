export default class Event {

  constructor() {
    this._listeners = [];
  }

  add(listener) {
    if (!listener) {
      console.error('listener is undefind');
      console.trace();
      return;
    }
    this._listeners = [ ...this._listeners, listener];
    return listener;
  };

  remove(listener) {
    this._listeners = this._listeners.filter(_listener => _listener !== listener);
  };

  removeAll() {
    this._listeners = [];
  }

  call(...args) {
    this._listeners.forEach(listener => {
      if (this._listeners.includes(listener)) listener(...args)
    });
  }
};
