import BaseController from '../baseSystemController';
import eBugReportFormControllerTypes from './eFormControllerTypes';
import FileDrop from '../components/fileDrop';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../../utils/constants';
import { eInputTypes } from '../../../../enums/inputTypes';

export default class ControllerReportForm extends BaseController {
  constructor({ container, ...props }) {
    super({ ...props });

    this._eInputTypes = {
      EIT_TEXTAREA: 'textarea',
    };

    this._eEventTypes = {
      TEXTAREA_CHANGE: this.getEventName(this._eInputTypes.EIT_TEXTAREA, eInputTypes.EIT_INPUT),
      SUBMIT: `${this.controllerType}__submit`,
    }

    this._eControllersClasses = {
      [this.controllerTypes.EBRFCT_FILE_DROP]: FileDrop
    }

    this._eElementsTypes = {
      EET_FORM: 'form',
      EET_TITLE: 'title',
      EET_DESCRIPTION: 'description',
      EET_TEXTAREA: this._eInputTypes.EIT_TEXTAREA,
      EET_CONTAINER_FILE_DROP: this.controllerTypes.EBRFCT_FILE_DROP,
      EET_BUTTON_SUBMIT: 'submit'
    }

    this._localizations = {
      [this._eElementsTypes.EET_TITLE]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.bugReport.title`,
      [this._eElementsTypes.EET_DESCRIPTION]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.bugReport.description`,
      [this._eElementsTypes.EET_BUTTON_SUBMIT]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.bugReport.submit`
    }
    this._placeholderLocalizationKey = `${OP_WRAPPER_LOCALIZATION_PREFIX}.bugReport.placeholder`;

    this.init(container);
  }

  onShow() {
    super.onShow();
    const { remove, execute } = window.OPWrapperService.SystemUI.showGoogleCaptcha();
    this._removeCaptcha = remove;
    this._executeCaptcha = execute;
  }

  _afterInit() {
    super._afterInit();
    const form = this.interactiveElements[this._eElementsTypes.EET_FORM];
    form.onsubmit = (e) => {
      e.preventDefault();
      this.block(this._eElementsTypes.EET_BUTTON_SUBMIT);

      this._executeCaptcha((captcha) => {
        const formData = new FormData(e.target);
        const data = {};
        for (let entry of formData) {
          data[entry[0]] = entry[1];
        }
        // override file. If file was drag n dropped it wouldn't be inside input;
        data.file = this.controllers[this.controllerTypes.EBRFCT_FILE_DROP].file;

        this.emit(this._eEventTypes.SUBMIT, { ...data, captcha });
      }, 'bug_report_form');
    }
  }

  cleanup() {
    super.cleanup();
    this.interactiveElements[this._eElementsTypes.EET_TEXTAREA].value = '';
    this.unblock(this._eElementsTypes.EET_BUTTON_SUBMIT);
    this._removeCaptcha && this._removeCaptcha();
  }

  _updateLocalizations() {
    super._updateLocalizations();
    this.interactiveElements[this._eElementsTypes.EET_TEXTAREA].setAttribute('placeholder', this._getLocalization(this._placeholderLocalizationKey));
  }

  get controllerTypes() {
    return eBugReportFormControllerTypes;
  }

  _getMarkup() {
    return `<form id=${this.interactiveElementsIds[this._eElementsTypes.EET_FORM]} class="system-ui-container__modal__bug_report__form">
              <div class="system-ui-container__modal__bug_report__form__head">
                <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_TITLE]} class="system-ui-container__modal__bug_report__form__head__title">
                  ${this._getLocalization(this._localizations[this._eElementsTypes.EET_TITLE])}
                </span>
                <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_DESCRIPTION]} class="system-ui-container__modal__bug_report__form__head__description">
                  ${this._getLocalization(this._localizations[this._eElementsTypes.EET_DESCRIPTION])}
                </span>
              </div>
              
              <div class="system-ui-container__modal__bug_report__form__inputs">
                <textarea name="description" id=${this.interactiveElementsIds[this._eElementsTypes.EET_TEXTAREA]} required="required" maxlength="5000" placeholder=${this._getLocalization(this._placeholderLocalizationKey)}></textarea>
                <div class="system-ui-container__modal__bug_report__form__inputs__file" id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_FILE_DROP]}></div>
              </div>
              
              <button type="submit" id=${this.interactiveElementsIds[this._eElementsTypes.EET_BUTTON_SUBMIT]}>
                ${this._getLocalization(this._localizations[this._eElementsTypes.EET_BUTTON_SUBMIT])}
              </button>
            </form>`
  }
}
