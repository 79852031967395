export default class BaseEventManager {
  get listeners() {
    if (!this._listeners) {
      this._listeners = [];
    }
    return this._listeners;
  }

  get eventsStack() {
    if (!this._eventsStack) {
      this._eventsStack = [];
    }
    return this._eventsStack;
  }

  set inProgress(value) {
    this._inProgress = value;
  }

  get inProgress() {
    if (!this._inProgress) {
      this._inProgress = false;
    }
    return this._inProgress;
  }

  add($event, $callback, $context, $priority) {
    let listener = { event: $event, callback: $callback, context: $context, priority: $priority || 1, once: false };
    this.addToListeners(listener);
  }

  addOnce($event, $callback, $context, $priority) {
    let listener = { event: $event, callback: $callback, context: $context, priority: $priority || 1, once: true };
    this.addToListeners(listener);
  }

  addToListeners($listener) {
    this._listeners = [ ...this.listeners, $listener ].sort(function (a, b) {
      return b.priority - a.priority;
    });
  }

  dispatch($event, $params) {
    this.eventsStack.push({event: $event, params: $params});
    if (!this.inProgress) {
      this.executeFromStack();
    }
  }

  remove($event, $callback, $context) {
    this._listeners = this.listeners.filter(listener=> !(listener.event === $event && listener.callback === $callback && listener.context === $context))
  }

  removeAll($event) {
    this._listeners = this.listeners.filter(listener=> listener.event !== $event);
  }

  _removeListener($listener) {
    this._listeners = this.listeners.filter(listener=> listener !== $listener);
  }

  executeFromStack() {
    this.inProgress = true;
    let object = null;
    while (this.eventsStack.length > 0) {
      object = this.eventsStack.shift();
      this.execute(object.event, object.params);
    }
    this.inProgress = false;
  }

  execute($event, $params) {
    this.listeners.forEach((listener) => {
      if (this.listeners.includes(listener) && listener.event === $event) { // this.listeners.includes(listener) because the listener can be removed during iteration
        if (listener.context) {
          listener.callback.call(listener.context, $params);
        } else {
          listener.callback($params);
        }
        if (listener.once) {
          this._removeListener(listener);
        }
      }
    });
  }
}
