export default new class EntryPoint {
    constructor() {
        this.mobile = PIXI.utils.isMobile.any;
        this._gameModel = null;
        this._gameSettings = null;
        this._configData = null;
        this._autoPlaySettings = null;
        this._game = null;
        this._nearWinCalculator = null;
        this._urlSettingsManager = null;
        this._movieClipsAccessor = null;
        this._playerUiAdapter = null;
        this._systemUiAdapter = null;
    }

    set mobile(value) {
        this._mobile = value;
    }

    get mobile() {
        return this._mobile;
    }

    set Game(value) {
        this._game = value;
    }

    get Game() {
        return this._game;
    }

    set GameModel(value) {
        this._gameModel = value;
    }

    get GameModel() {
        return this._gameModel;
    }

    set GameSettings(value) {
        this._gameSettings = value;
    }

    get GameSettings() {
        return this._gameSettings;
    }

    set configData(value) {
        this._configData = value;
    }

    get configData() {
        return this._configData;
    }

    get AutoPlaySettings() {
        return this._autoPlaySettings;
    }

    set AutoPlaySettings(value) {
        this._autoPlaySettings = value;
    }

    get NearWinCalculator() { //toDo: need to remove
        return this._nearWinCalculator;
    }

    set NearWinCalculator(value) { //toDo: need to remove
        this._nearWinCalculator = value;
    }

    get UrlSettingsManager() { //toDo: need to remove
        return this._urlSettingsManager;
    }

    set UrlSettingsManager(value) { //toDo: need to remove
        this._urlSettingsManager = value;
    }

    get MovieClipsAccessor() {
        return this._movieClipsAccessor;
    }

    set MovieClipsAccessor(value) {
        this._movieClipsAccessor = value;
    }

    get PlayerUiAdapter() {
        return this._playerUiAdapter;
    }

    set PlayerUiAdapter(value) {
        this._playerUiAdapter = value;
    }

    get SystemUiAdapter() {
        return this._systemUiAdapter;
    }

    set SystemUiAdapter(value) {
        this._systemUiAdapter = value;
    }
}
