import LocalizationManager from '../i18n/LocalizationManager';
import { errorTypes } from '../utils/errorTypes';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../utils/constants';
import { getUrlParam } from '../utils/url';
import { disableDoubleTapToZoom } from '../utils/disableDoubleTapToZoom';

const OPWrapperErrorName = 'OPWrapperError';

class OPWrapperError extends Error {
  constructor(message) {
    super(message);
    this.name = OPWrapperErrorName;
  }
}

export default class MessageWindow {
  constructor() {
    this.needReload = true;
    this.errors = errorTypes;
    this.OPWrapperErrorName = OPWrapperErrorName;

    document.body.insertAdjacentHTML('beforeend',
      `
      <div id="messageView" class="opWrapperMessageBlocker">
        <div class="messageContainer">
          <div class="messageTextContainer">
            <div id="messageTitle">Title</div>
            <div id="messageDesc">Some error described here</div>
          </div>
          <div class="messageButtonContainer">
            <div id="buttonOk">OK</div>
            <a id="customButton" target="_parent"></a>
          </div>
        </div>
      </div>`
    );

    this.view = document.getElementById('messageView');
    this.title = document.getElementById('messageTitle');
    this.desc = document.getElementById('messageDesc');
    this.buttonOk = document.getElementById('buttonOk');
    this.customButton = document.getElementById('customButton');
    this.buttonOk.addEventListener('click', () => {
      if (this.needReload) window.location.reload();
      this.hide();
      window.OPWrapperService.initConfig.onButtonClick();
    });
    
    disableDoubleTapToZoom(this.view);
  }

  get lastCode() {
    return this._lastCode;
  }

  show(title, description, showOkButton = false, needReload = true, customButtonContent, customButtonURL) {
    this.needReload = needReload;
    this.buttonOk.style.setProperty('display', showOkButton ? 'block' : 'none');
    this.view.style.setProperty('display', 'block');
    this.title.innerText = title;

    if (description == null) description = 'No error message provided';
    this.desc.innerText = description;

    if (customButtonContent) {
      this.customButton.style.setProperty('display', 'block');
      this.customButton.href = customButtonURL;
      this.customButton.textContent = customButtonContent;
    }
  }

  showError (code, messageFallback = null, needToReloadForce) {
    this._lastCode = code;
    window.OPUtility.ControllerStatistic.sendError(code, messageFallback);
    let error = Object.values(this.errors).find(el => el.CODE === code);
    let titleLocalizationKey = `${OP_WRAPPER_LOCALIZATION_PREFIX}.errors.title`;
    let messageLocalizationKey = `${OP_WRAPPER_LOCALIZATION_PREFIX}.errors.error_${code}`;
    let title = LocalizationManager.getLocalizedText(titleLocalizationKey);
    let message = LocalizationManager.getLocalizedText(messageLocalizationKey);
    if (message === messageLocalizationKey) message = null;
    let showOk = error ? error.SHOW_OK_BUTTON : true;
    let needToReload = error ? error.NEED_RELOAD : true;
    const showHomeButton = error ? error.SHOW_HOME_BUTTON : false;

    if (title === titleLocalizationKey || !title) {
      title = 'Error';
    }

    title = code ? `${title} (${code})` : title;

    if ((message === messageLocalizationKey || !message)) {
      message = messageFallback ? messageFallback : 'Server error';
    }

    window.OPUtility.eventManager.dispatch(
      window.OPUtility.eventManager.postMessageTypes.PMT_ERROR_MESSAGE,
      {
        code,
        message
      }
    );

    const needReload = typeof needToReloadForce === 'boolean' ? needToReloadForce : needToReload;

    const customButtonURL = getUrlParam('lobbyUrl');

    if (showHomeButton && customButtonURL) {
      const customButtonContent= 'HOME';
      this.show(title, message, showOk, needReload, customButtonContent, customButtonURL);
    } else this.show(title, message, showOk, needReload);

    if (needReload) {
      const errorMessage = `code: ${code}, message: ${messageFallback}`
      throw new OPWrapperError(errorMessage);
    }
  }

  hideError(code) {
    if (this._lastCode === code) {
      this.hide();
    }
  }
  hide() {
    this.view.style.setProperty('display', 'none');
  }
}
