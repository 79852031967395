import BasePlayerUi from '../player/basePlayerUI';
import logo from '../../../assets/images/onlyplay_logo_white.svg'
export default class LandscapeMobileUi extends BasePlayerUi {
  constructor({ config, partnerConfig }) {
    super({ config, partnerConfig });

    this._config = {
     ...this._config,
      offsets: {
        left_panel: {
          top: 0,
          left: 0,
        },
        right_panel: {
          top: 0,
          left: 0,
        },
        bottom_panel: {
          top: 0,
          left: 0,
        },
        tournaments_panel: {
          top: 0,
          left: 0,
        }
      }
    };

    if (config && config.offsets) {
      this._config.offsets.left_panel = { ...this._config.offsets.left_panel, ...config.offsets.left_panel };
      this._config.offsets.right_panel = { ...this._config.offsets.right_panel, ...config.offsets.right_panel };
      this._config.offsets.bottom_panel = { ...this._config.offsets.bottom_panel, ...config.offsets.bottom_panel };
      this._config.offsets.tournaments_panel = { ...this._config.offsets.tournaments_panel, ...config.offsets.tournaments_panel };
    }

    this.init();
  }

  init(container) {
    super.init(container);
    this.root.setAttribute('data-orientation', 'landscape');
  }

  _getConstantMarkup() {
    return `<div class="ui-container__tournaments_label_container" data-orientation="landscape" style="top: ${window.OPWrapperService.ScaleManager.convertPxToRem(this._config.offsets.tournaments_panel.top)}rem; left: ${window.OPWrapperService.ScaleManager.convertPxToRem(this._config.offsets.tournaments_panel.left)}rem">
      <div id=${this.controllersContainersIds[this.controllerTypes.ECT_TOURNAMENTS_LABEL]}>
      </div>
    </div>`
  }

  _getMarkup() {
    return `
    ${this._getConstantMarkup()}

<!--    modals    -->
    <div class="ui-container__bet_selector_container" data-orientation="landscape" data-device-type="mobile" style="padding: 0 ${window.OPWrapperService.ScaleManager.convertPxToRem(-this._config.offsets.right_panel.left)}rem ${window.OPWrapperService.ScaleManager.convertPxToRem(-this._config.offsets.bottom_panel.top)}rem 0">
      <div id=${this.controllersContainersIds[this.controllerTypes.ECT_BET_SELECTOR_TABLE]}>
      </div>
    </div>
    <div class="ui-container__auto_play_table" data-orientation="landscape" data-device-type="mobile" style="padding: 0 ${window.OPWrapperService.ScaleManager.convertPxToRem(-this._config.offsets.right_panel.left)}rem ${window.OPWrapperService.ScaleManager.convertPxToRem(-this._config.offsets.bottom_panel.top)}rem 0">
      <div id=${this.controllersContainersIds[this.controllerTypes.ECT_AUTO_PLAY_TABLE]}>
      </div>
    </div>
        

<!--    panels    -->
    <div class="ui-container__info_panel">
    <div class="ui-container__info_panel__container" data-orientation="landscape" data-device-type="mobile">
      <div class="ui-container__info_panel__left">
        <img class="ui-container__info_panel__logo" src="${logo}" alt="logo">
        <span>${document.title}</span>
        </div>
        <div class="ui-container__info_panel__right">
        <!-- clock -->
          <div class="ui-container__info_panel__list" id="${this.controllersContainersIds[this.controllerTypes.ECT_INFO_PANEL]}"></div>
          <div id="${this.controllersContainersIds[this.controllerTypes.ECT_INFO_PANEL_CLOCK]}"></div>
        </div>
      </div>
    </div>
    
    <div class="ui-container__landscape-mobile">
      <div class="ui-container__landscape-mobile_left-panel">
        <div class="ui-container__landscape-mobile_left-panel_container" style="top: ${window.OPWrapperService.ScaleManager.convertPxToRem(this._config.offsets.left_panel.top)}rem; left: ${window.OPWrapperService.ScaleManager.convertPxToRem(this._config.offsets.left_panel.left)}rem"> 
          <!--    menu button    -->
          <div class="ui-container__controller_menu" id=${this.controllersContainersIds[this.controllerTypes.ECT_MENU]} data-orientation="landscape">
          </div>  
          <!--    info    -->
          <div class="ui-container__controller_info" id=${this.controllersContainersIds[this.controllerTypes.ECT_INFO]}>
          </div>
          <!--    sound    -->
          <div class="ui-container__controller_sound" id=${this.controllersContainersIds[this.controllerTypes.ECT_SOUND]}>
          </div>
        </div>
      </div>
      <div class="ui-container__landscape-mobile_bottom-panel">
        <div class="ui-container__landscape-mobile_bottom-panel_container" style="padding-bottom: ${window.OPWrapperService.ScaleManager.convertPxToRem(-this._config.offsets.bottom_panel.top)}rem; left: ${window.OPWrapperService.ScaleManager.convertPxToRem(this._config.offsets.bottom_panel.left)}rem">
            <div class="ui-container__landscape-mobile_bottom-panel_container__background ui-container__panel"></div>
            <div class="ui-container__landscape-mobile_bottom-panel_container__left_block">
              
              <!--      balance     -->
              <div class="ui-container__controller_balance" id=${this.controllersContainersIds[this.controllerTypes.ECT_BALANCE]}>
              </div>
            </div>
            
            <div class="ui-container__landscape-mobile_bottom-panel_container__center_block">
              <!--      win / last win     -->
              <div class="top_container" id=${this.controllersContainersIds[this.controllerTypes.ECT_WIN]}>
              </div>
              <!--      label     -->
              <div class="top_container" id=${this.controllersContainersIds[this.controllerTypes.ECT_LABEL]}>
              </div>
              <!--     total win     -->
              <div  class="bottom_container" id=${this.controllersContainersIds[this.controllerTypes.ECT_TOTAL_WIN]}>
              </div>
              <!--     auto spin count     -->
              <div  class="bottom_container" id=${this.controllersContainersIds[this.controllerTypes.ECT_AUTO_SPIN_COUNT]}>
              </div>
              <!--     win line label     -->
              <div  class="bottom_container ui-container__win_line_label" id=${this.controllersContainersIds[this.controllerTypes.ECT_WIN_LINE_LABEL]}>
              </div>
            </div>
            
            <div class="ui-container__landscape-mobile_bottom-panel_container__right_block">
              <!--      bet     -->
              <div class="ui-container__controller_bet" id=${this.controllersContainersIds[this.controllerTypes.ECT_BET]}>
              </div>
            </div>
        </div>
      </div>
      <div class="ui-container__landscape-mobile_right-panel">
        <div class="ui-container__landscape-mobile_right-panel_container" style="top: ${window.OPWrapperService.ScaleManager.convertPxToRem(this._config.offsets.right_panel.top)}rem; left: ${window.OPWrapperService.ScaleManager.convertPxToRem(this._config.offsets.right_panel.left)}rem"> 
          <!--    turbo play   -->
          <div id=${this.controllersContainersIds[this.controllerTypes.ECT_TURBO_SPIN]} data-orientation="landscape">
          </div>
          <!--    spin    -->
          <div class="ui-container__controller_spin" id=${this.controllersContainersIds[this.controllerTypes.ECT_SPIN]}>
          </div>
          <!--    autoplay    -->
          <div id=${this.controllersContainersIds[this.controllerTypes.ECT_AUTO_SPIN]} data-orientation="landscape">
          </div> 
          <!--    coins button     -->
          <div class="ui-container__controller_bet_selector" id=${this.controllersContainersIds[this.controllerTypes.ECT_BET_SELECT]} data-orientation="landscape">
          </div>
 
        </div>
      </div>
    </div>`
  }
}
