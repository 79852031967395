import BaseSystemController from '../system/baseSystemController';
import { TournamentNotification, SystemNotification } from './system';
import eControllerTypes from '../system/eControllerTypes';
import eNotificationTypes from './eNotificationTypes';

export default class ControllerNotificationsManager extends BaseSystemController {
  constructor({ container, ...props }) {
    super({ type: eControllerTypes.ECT_NOTIFICATIONS, ...props });
    this._defaultSizes = {
      H: {
        width: 1920,
        height: 1080,
      },
      V: {
        width: 918,
        height: 1920
      }
    };

    this._eElementsTypes = {
      EET_CONTAINER_ROOT: 'container_root',
      EET_CONTAINER_NOTIFICATIONS: 'container_notifications',
    }
    this._notificationClasses = {
      [eNotificationTypes.ENT_TOURNAMENT_STATUS_CHANGED]: TournamentNotification,
      [eNotificationTypes.ENT_SYSTEM]: SystemNotification,
    };

    this.eNotificationTypes = eNotificationTypes;

    this._activeNotifications = [];
    this.init(container);
  }

  _afterInit() {
    super._afterInit();
    this._createIdGenerator();
    this.root = this._interactiveElements[this._eElementsTypes.EET_CONTAINER_ROOT];
  }

  showNotification(type, data) {
    if (!this._notificationClasses.hasOwnProperty(type)) return console.error(`Unhandled notification type: '${type}'`);
    this._execute(type, data);
  }

  hideNotification(type, data) {
    this._removeActiveNotificationByType(type, data);
  }

  _execute(type, data) {
    if (this._checkNotificationAlreadyExist(type, data)) return;
    this._removeActiveNotificationByType(type, data);

    const activeNotification = new this._notificationClasses[type]({ ...data, _id: this._idGenerator.next().value });
    activeNotification.init(this.interactiveElements[this._eElementsTypes.EET_CONTAINER_NOTIFICATIONS]);
    activeNotification.on(activeNotification.COMPLETE_EVENT, (controller) => {
      this._activeNotifications = this._activeNotifications.filter((notification) => notification !== activeNotification);
      controller.remove();
    });
    this._activeNotifications.push(activeNotification);
  }

  _checkNotificationAlreadyExist(type, data) {
    return this._activeNotifications.some(notification => notification.controllerType === type && notification.compareWithData(data))
  }

  _removeActiveNotificationByType(type, data) {
    this._activeNotifications = this._activeNotifications.filter(notification => {
      if (notification.controllerType === type) {
        return !notification.tryKill(data);
      } else {
        return true;
      }
    });
  }

  _createIdGenerator() {
    this._idGenerator = (function* () {
      let id = 0;
      while (true) {
        yield id;
        id++;
      }
    })();
  }

  set scaleData({ scaleData, rootScale }) {
    this._scaleData = scaleData;
    const orientation = this._scaleData.innerWidth <= this._scaleData.innerHeight ? 'V' : 'H';
    const uiScale = Math.min(this._scaleData.innerWidth / this._defaultSizes[orientation].width, this._scaleData.innerHeight / this._defaultSizes[orientation].height);
    for (const key in this.controllers) {
      const controller = this.controllers[key];
      controller.scaleData = { scaleData: this._scaleData, rootScale: uiScale };
    }

    const ScaleManager = window.OPWrapperService.ScaleManager;

    if (ScaleManager.safeZone) {
      //repeating the behavior of the canvas and its stage
      const marginX = ScaleManager.safeZone.left + this._scaleData.stageX / this._scaleData.scale;
      const marginY = ScaleManager.safeZone.top + this._scaleData.stageY / this._scaleData.scale;

      this.root.style.marginRight = `${marginX / ScaleManager.defaultFontSize}rem`;
      this.root.style.marginLeft = `${marginX / ScaleManager.defaultFontSize}rem`;
      this.root.style.marginTop = `${marginY / ScaleManager.defaultFontSize}rem`;
      this.root.style.marginBottom = `${marginY / ScaleManager.defaultFontSize}rem`;
      this.root.style.height = `${ScaleManager.safeZone.height / ScaleManager.defaultFontSize}rem`;
    }

    ScaleManager.makeScalingHTMLElement({
      HTMLElement: this.root.parentElement,
      relativeScale: uiScale,
      defaultWidth: this._scaleData.innerWidth / uiScale,
      defaultHeight: this._scaleData.innerHeight / uiScale
    });
  }

  _getMarkup() {
    return `<div id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_ROOT]} class="OPWrapper_service__notifications system-ui-container__notifications">
          <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_NOTIFICATIONS]} class="OPWrapper_service__notifications__wrapper">
          </div>
        </div>`
  }
}
