import BaseSystemController from '../system/baseSystemController';
import eButtonsTypes from '../../../sideBar/eButtonsTypes';
import LocalizationManager from '../../../i18n/LocalizationManager';
import ButtonSound from '../../../sideBar/ButtonSound';
import { EMPostMessageTypes } from '../../../platforms/everyMatrix/constants';
import { getUrlParam } from '../../../utils/url';
import { isIframe } from '../../../utils/isIframe';
import { OP_WRAPPER_LOCALIZATION_PREFIX } from '../../../utils/constants';
import ScaleManager from '../../../scaleManager/ScaleManager';

export default class ControllerSidebar extends BaseSystemController {
  constructor({ container, ...props }) {
    super({ ...props });
    this._defaultSizes = {
      H: {
        width: 1920,
        height: 1080,
      },
      V: {
        width: 1040,
        height: 1800
      }
    };
    this._eButtonsTypes = {
      EBT_CLOSE: eButtonsTypes.EBT_CLOSE,
    }
    this._eButtonsActions = {
      [this._eButtonsTypes.EBT_CLOSE]: () => {
        this.hide();
        window.OPWrapperService.model.data.initConfig.onButtonClick();
      },
    };
    this._eElementsTypes = {
      EET_OVERLAY: 'overlay',
      EET_SCALE_ROOT: 'scale_root',
      EET_TITLE: 'title',
      EET_GAME_VERSION_CONTAINER: 'game_version_container',
      EET_BUTTON_CLOSE: this._eButtonsTypes.EBT_CLOSE,
      EET_CONTAINER_BUTTONS: 'container_buttons',
    };
    this._localizations = {
      [this._eElementsTypes.EET_TITLE]: `${OP_WRAPPER_LOCALIZATION_PREFIX}.sideBar.titleV2`,
    }

    this._eEventTypes = Object.values(eButtonsTypes).reduce((acc, type) => {
      acc[type] = this.getEventName(type);
      return acc;
    }, {});

    this._buttonsPriority = {
      [eButtonsTypes.EBT_HOME]: 0,
      [eButtonsTypes.EBT_PAYTABLE]: 1,
      [eButtonsTypes.EBT_RULES]: 2,
      [eButtonsTypes.EBT_SETTINGS]: 3,
      [eButtonsTypes.EBT_SOUND]: 4,
      [eButtonsTypes.EBT_INFO]: 5,
      [eButtonsTypes.EBT_LEADERBOARD]: 6,
      [eButtonsTypes.EBT_GAME_LIMITS]: 7,
      [eButtonsTypes.EBT_TOURNAMENTS]: 8,
      [eButtonsTypes.EBT_HISTORY]: 9,
      [eButtonsTypes.EBT_BUG_REPORT]: 10,
    }

    this._requestedButtons = new Set();
    this._initializedButtons = new Set();

    this._isSpinBlocker = true;

    this._container = container;
    this._onOutsideClick = this._onOutsideClick.bind(this);
  }

  init() {
    super.init(this._container);

    this._overlay = this.interactiveElements[this._eElementsTypes.EET_OVERLAY];
    this._overlay.onclick = this._onOutsideClick;

    this.scaleRoot = this.interactiveElements[this._eElementsTypes.EET_SCALE_ROOT];
    this._overlayTransitionStyle = this._overlay.style.transition;
    this._wrapperTransitionStyle = this.scaleRoot.style.transition;

    this.scaleData = { scaleData: this._scaleData };

    this._tryInitHomeButton();
    this._tryInitLimitsButton();
    this._tryInitTournamentsButton();
    this._tryInitHistoryButton();
    this._tryInitSettingsButton();
    this._initBugReportButton();
    this._initButtons();
    super.hide();
  }

  show() {
    super.show();
    void this.interactiveElements[this._eElementsTypes.EET_OVERLAY].offsetWidth;
    this.interactiveElements[this._eElementsTypes.EET_OVERLAY].classList.add('shift');
  }

  hide() {
    const sidebar = this.interactiveElements[this._eElementsTypes.EET_OVERLAY];
    sidebar.classList.remove('shift');
    this._timeoutForHide = setTimeout(() => {
      super.hide();
    }, 400);
  }

  tryInitTournamentsButton() {
    if (this._tryInitTournamentsButton()) {
      this.updateButtons();
    }
  }

  _onOutsideClick(e) {
    if (e.target === this.interactiveElements[this._eElementsTypes.EET_OVERLAY]) this.hide();
  }

  _initButtons() {
    this._removeButtons();
    this._config.buttons.sort((a, b) => this._buttonsPriority[a.type] - this._buttonsPriority[b.type]);
    const buttonsContainer = this.interactiveElements[this._eElementsTypes.EET_CONTAINER_BUTTONS];
    const buttons = this._buttonsList(this._config.buttons);
    buttonsContainer.append(...buttons);
    buttonsContainer.style.gridTemplateRows = `repeat(${Math.ceil(buttons.length / 2)}, minmax(0, 1fr))`;
  }

  _removeButtons() {
    this._initializedButtons.clear();
    const buttonsContainer = this.interactiveElements[this._eElementsTypes.EET_CONTAINER_BUTTONS];
    buttonsContainer.innerHTML = '';
    this.homeButton = null;
    this.rulesButton = null;
    this.ButtonSound = null;
  }

  updateButtons() {
    if (!this._mounted) return;
    this._initButtons();
  }

  addButton(data) {
    this._addButtons([data]);
    this.updateButtons()
  }

  isButtonInitialized(type) {
    return this._initializedButtons.has(type);
  }

  _buttonsList(buttons) {
    return buttons.map((item) => {
      this._initializedButtons.add(item.type);

      if (item.type === eButtonsTypes.EBT_SOUND) {
        this.ButtonSound = new ButtonSound(item);
        return this.ButtonSound.element;
      }

      return this._createButton(item);
    });
  }

  _createButton({ type, styles, onClick }) {
    const button = document.createElement('button');

    button.addEventListener('click', () => {
      this.hide();
      onClick();
      this.emit(this.getEventName(type));
      window.OPWrapperService.model.data.initConfig.onButtonClick();
    });
    if (type === eButtonsTypes.EBT_HOME) this.homeButton = button;
    if (type === eButtonsTypes.EBT_RULES) this.rulesButton = button;

    button.innerHTML = `<div class="icon ${styles && styles.join(' ')}"></div>
                        <span class="text">${LocalizationManager.getLocalizedText(`OPWrapperService.buttons.${type}`)}</span>`
    return button;
  };

  _addButtons(buttons = []) {
    this._config.buttons = [...this._config.buttons, ...buttons];
    buttons.forEach(button => this._requestedButtons.add(button.type));
  }

  _tryInitLimitsButton() {
    if (window.OPWrapperService.model.data.partnerConfig.available_bets && window.OPWrapperService.model.data.partnerConfig.maxWin) {
      this.addButton({
        type: eButtonsTypes.EBT_GAME_LIMITS,
        styles: ['gameLimits'],
        onClick: () => window.OPWrapperService.SystemUI.toggleControllerVisibility(window.OPWrapperService.SystemUI.controllerTypes.ECT_WIN_LIMITS),
      });
    }
  }

  _tryInitHomeButton() {
    const data = this._getHomeButtonData();
    if (data) this.addButton(data);
  }

  _tryInitSettingsButton() {
    if (this._config.buttons.find(({ type }) => type === eButtonsTypes.EBT_SETTINGS)) return;
    this.addButton({
      type: eButtonsTypes.EBT_SETTINGS,
      styles: ['settings'],
      onClick: () => window.OPWrapperService.SystemUI.toggleControllerVisibility(window.OPWrapperService.SystemUI.controllerTypes.ECT_GAME_SETTINGS),
    });
  }

  _initBugReportButton() {
    this.addButton({
      type: eButtonsTypes.EBT_BUG_REPORT,
      styles: ['bugReport'],
      onClick: () => window.OPWrapperService.SystemUI.toggleControllerVisibility(window.OPWrapperService.SystemUI.controllerTypes.ECT_BUG_REPORT),
    });
  }

  _tryInitTournamentsButton() {
    if (window.OPWrapperService.model.data.tournamentsInfo &&
      (window.OPWrapperService.model.data.tournamentsInfo.active.length ||
        window.OPWrapperService.model.data.tournamentsInfo.archived.length) &&
      !this._initializedButtons.has(eButtonsTypes.EBT_TOURNAMENTS) &&
      !this._requestedButtons.has(eButtonsTypes.EBT_TOURNAMENTS)) {
      this.addButton({
        type: eButtonsTypes.EBT_TOURNAMENTS,
        styles: ['tournament'],
        onClick: () => window.OPWrapperService.SystemUI.showTournaments(),
      });

      return true;
    }
  }

  _tryInitHistoryButton() {
    if (!window.OPWrapperService.model.data.partnerConfig.isRoundsHistoryAvailable) return;
    this.addButton({
      type: eButtonsTypes.EBT_HISTORY,
      styles: ['history'],
      onClick: () => window.OPWrapperService.SystemUI.toggleControllerVisibility(window.OPWrapperService.SystemUI.controllerTypes.ECT_ROUNDS_HISTORY),
    });
  }

  _getHomeButtonData() {
    const search = window.location.search;
    const history = window.history;
    const homeURL = getUrlParam('lobbyUrl');
    let info = { type: eButtonsTypes.EBT_HOME, styles: ['home'] };
    const iFrameButton = window.OPWrapperService.model.data.partnerConfig.iFrameHomeButton;
    const homeButtonHistoryBackDisable = window.OPWrapperService.model.data.partnerConfig.homeButtonHistoryBackDisable;
    if (!isIframe() && (homeURL || (!homeButtonHistoryBackDisable && window.history.length > 1))) {
      info.onClick = () => {
        if (homeURL) {
          window.top.location.href = homeURL;
        } else {
          history.back();
        }
      }
    } else if (homeURL && iFrameButton) {
      info.onClick = () => {
        window.top.location.href = homeURL;
        window.OPWrapperService.eventManager.dispatch(EMPostMessageTypes.PMT_CLOSED);
      }
    } else {
      if (!homeURL) console.warn(`No lobbyUrl presented: ${search}`);
      info = null;
    }

    return info;
  }

  set gameVersion(value) {
    this._gameVersion = value;
    this.interactiveElements[this._eElementsTypes.EET_GAME_VERSION_CONTAINER].textContent = `${LocalizationManager.getLocalizedText(`${OP_WRAPPER_LOCALIZATION_PREFIX}.sideBar.gameVersion`)} ${this._gameVersion}`;
  }

  get gameVersion() {
    return this._gameVersion;
  }

  set config(config) {
    this._config = config;
    this._requestedButtons = new Set(this._config.buttons.map(button => button.type));
  }

  set scaleData({ scaleData, rootScale }) {
    this._scaleData = scaleData;
    if (!this._mounted) return;

    clearTimeout(this._transitionStylesTimeout);

    this._overlay.style.transition = 'none';
    this.scaleRoot.style.transition = 'none';

    const orientation = this._scaleData.orientation;
    const uiScale = Math.min(this._scaleData.innerWidth / this._defaultSizes[orientation].width, this._scaleData.innerHeight / this._defaultSizes[orientation].height);
    this._overlay.dataset.orientation = scaleData.isPortrait ? 'portrait' : 'landscape';
    this._overlay.dataset.deviceType = scaleData.isMobile ? 'mobile' : 'desktop';
    clearTimeout(this._timeoutForHide);

    ScaleManager.makeScalingHTMLElement({
      HTMLElement: this._overlay,
      relativeScale: uiScale,
      defaultWidth: this._scaleData.innerWidth / uiScale + 2,
      defaultHeight: this._scaleData.innerHeight / uiScale,
    });

    this._transitionStylesTimeout = setTimeout(() => {
      this._overlay.style.transition = this._overlayTransitionStyle;
      this.scaleRoot.style.transition = this._wrapperTransitionStyle;
    }, 100);
  }

  _getMarkup() {
    return `<div id=${this.interactiveElementsIds[this._eElementsTypes.EET_OVERLAY]} class="system-ui-container__sidebar">
              <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_SCALE_ROOT]} class="system-ui-container__sidebar__wrapper">
                  <div class="system-ui-container__sidebar__wrapper__header">
                    <span id=${this.interactiveElementsIds[this._eElementsTypes.EET_TITLE]}>${this._getLocalization(this._localizations[this._eElementsTypes.EET_TITLE])}</span>
                    <div>
                      <button id=${this.interactiveElementsIds[this._eElementsTypes.EET_BUTTON_CLOSE]} class="system-ui-container__close_button system-ui-container__sidebar__wrapper_close">
                        <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M52 24L24 52M24 24L52 52" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div class="system-ui-container__sidebar__wrapper__body">
                    <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_CONTAINER_BUTTONS]} class="system-ui-container__sidebar__wrapper__body_content">
                    </div>
                    
                    <div id=${this.interactiveElementsIds[this._eElementsTypes.EET_GAME_VERSION_CONTAINER]} class="sidebar_gameVersion"></div>
                  </div>
              </div>
            </div>`
  }
}
